<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Listado de clientes</span
              >
              <span class="text-muted mt-3 font-weight-bold font-size-sm"
                >{{ rows }} Instancias encontradas en el sistema</span
              >
            </h3>
            <div class="card-toolbar">
              <a
                href="#/clients/new"
                class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                >Nuevo Cliente</a
              >
              <!-- <a href="#" class="btn btn-danger font-weight-bolder font-size-sm"
                >Create</a
              > -->
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div>
              <div>
                <div class="input-group input-group-lg input-group-solid mb-8">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg inline"
                    name="address1"
                    placeholder="Busqueda"
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    hide-details
                  />
                </div>
              </div>
              <div class="tab-content position-relative">
                <LoaderPartial v-if="showLoader"></LoaderPartial>
                <!--begin::Table-->
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                  >
                    <thead>
                      <tr class="text-left">
                        <th
                          style="min-width: 250px"
                          class="pl-7 hover"
                          @click="sortListFor('tenant')"
                        >
                          <span class="text-dark-75"
                            >Instancia
                            <i
                              class="fa fa-angle-up"
                              aria-hidden="true"
                              v-if="sortingList.tenant == 'DESC'"
                            ></i>
                            <i
                              class="fa fa-angle-down"
                              aria-hidden="true"
                              v-if="sortingList.tenant == 'ASC'"
                            ></i>
                          </span>
                        </th>
                        <th
                          style="min-width: 100px"
                          class="hover"
                          @click="sortListFor('client')"
                        >
                          Cliente
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.client == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.client == 'ASC'"
                          ></i>
                        </th>
                        <!-- <th style="min-width: 100px">Suscripción</th> -->
                        <th
                          style="min-width: 100px"
                          class="hover"
                          @click="sortListFor('points')"
                        >
                          Créditos
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.points == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.points == 'ASC'"
                          ></i>
                        </th>
                        <th style="min-width: 100px">Estado</th>
                        <th
                          style="min-width: 100px"
                          class="hover"
                          @click="sortListFor('createdAt')"
                        >
                          Creación
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.createdAt == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.createdAt == 'ASC'"
                          ></i>
                        </th>
                        <th style="min-width: 100px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in tenants">
                        <tr v-bind:key="i">
                          <td class="pl-0 py-8">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-50 symbol-light mr-4">
                                <span
                                  class="symbol-label"
                                  style="overflow:hidden;"
                                >
                                  <img
                                    :src="item.image"
                                    class="h-100 align-self-end"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div>
                                <a
                                  :href="`#/clients/dash/${item.id}`"
                                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                  >{{ item.name }}</a
                                >
                                <span
                                  class="text-muted font-weight-bold d-block"
                                  >{{ item.tenant }}.marketplace.lexic.ai</span
                                >
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="text-muted font-weight-bold">{{
                              item.client
                            }}</span>
                          </td>
                          <!-- <td>
                            <span class="text-muted font-weight-bold">{{ item.suscriptions.join(", ") }}</span>
                          </td> -->
                          <td>
                            <span
                              class="text-muted font-weight-bold"
                              v-if="item.wallet && item.wallet.credits"
                              >{{ item.wallet.credits | amount }}</span
                            >
                            <span class="text-muted font-weight-bold" v-else>
                              0
                            </span>
                          </td>
                          <td>
                            <b-badge
                              class="mr-1"
                              variant="success"
                              v-if="item.active"
                              >Activo</b-badge
                            >
                            <b-badge
                              class="mr-1"
                              variant="danger"
                              v-if="!item.active"
                              >Inactivo</b-badge
                            >
                            <span class="text-muted font-weight-bold d-block">{{
                              item.company_desc
                            }}</span>
                          </td>
                          <td>
                            <!-- <img
                              src="/media/logos/stars.png"
                              alt="image"
                              style="width: 5rem"
                            /> -->
                            <span class="text-muted font-weight-bold d-block">{{
                              new Date(item.createdAt)
                                .toLocaleString()
                                .split(" ")[0]
                            }}</span>
                          </td>
                          <td class="pr-0 text-right">
                            <b-dropdown id="dropdown-1" text="Acciones">
                              <b-dropdown-item :href="`#/clients/dash/${item.id}`"
                                >Detalles</b-dropdown-item
                              >
                              <b-dropdown-item :href="`#/clients/${item.id}`"
                                >Editar</b-dropdown-item
                              >
                              <b-dropdown-item
                                v-if="item.active"
                                @click="disableResource(item.id)"
                                ><span style="color:red;"
                                  >Desactivar</span
                                ></b-dropdown-item
                              >
                              <b-dropdown-item
                                v-if="!item.active"
                                @click="enableResource(item.id)"
                                ><span style="">Activar</span></b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="showDeleteModal(item)"
                                ><span style="color:red;"
                                  >Eliminar</span
                                ></b-dropdown-item
                              >
                            </b-dropdown>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div>
                    <div class="row">
                      <div class="col m-1 ">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          page-click=""
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                      <div class="col m-1 text-right">
                        Elementos por pagina
                        <b-form-select
                          v-model="perPage"
                          :options="optionPerPage"
                          class="mb-3"
                          style="width:70px;"
                          value-field="item"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Table-->
              </div>
            </div>
          </div>
          <b-modal ref="deleteClientModal" centered :title="'Eliminar ' + deleteModal.clientName" header-bg-variant="danger" header-text-variant="light">
            <div class="mb-4">
              Para eliminar permanentemente este cliente, escribe el nombre del cliente <span style="font-style:italic;font-weight:bold">"{{deleteModal.clientName}}"</span> en el recuadro siguiente.<br><strong>Esta acción no es reversible.</strong>
            </div>
            <b-form-input 
              v-model="deleteModal.confirmation" 
              placeholder="Escribe el nombre del cliente"
              :disabled="deleteModal.loading"
              :state="deleteModal.clientName == deleteModal.confirmation.trim()"
            ></b-form-input>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="secondary"
                  size="sm"
                  class="float-right ml-3"
                  @click="$refs['deleteClientModal'].hide()"
                  :disabled="deleteModal.loading"
                >
                  Cancelar
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  class="float-right"
                  @click="deleteResource(deleteModal.clientId)"
                  :disabled="deleteModal.loading || deleteModal.clientName != deleteModal.confirmation.trim()"
                >
                  <b-spinner small v-show="deleteModal.loading"></b-spinner>
                  Eliminar
                </b-button>
              </div>
            </template>
          </b-modal>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<style scoped>
table th.hover {
  cursor: pointer;
}
table th.hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";
import LoaderPartial from "@/view/content/LoaderPartial.vue";

export default {
  name: "clients",
  data() {
    return {
      resource: "tenancies",
      search: "",
      tenants: [],
      deleteModal: {
        clientName: "",
        confirmation: "",
        clientId: null,
        loading: false,
      },
      currentPage: 1,
      rows: 0,
      perPage: 5,
      optionPerPage: [5, 10, 25, 50, 100],
      sortingList: {},

      dialog: false,
      showLoader: true
    };
  },
  components: {
    LoaderPartial
  },
  mounted() {
    this.getResourceList();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Clientes" }]);
  },
  watch: {
    search: function() {
      this.getResourceList();
    },
    perPage: function() {
      this.getResourceList();
    },
    currentPage: function() {
      this.getResourceList();
    }
  },
  methods: {
    sortListFor(row) {
      if (!this.sortingList[row]) {
        this.sortingList = []; // Clear?
        this.sortingList[row] = "DESC";
      } else if (this.sortingList[row] == "ASC") this.sortingList[row] = "DESC";
      else if (this.sortingList[row] == "DESC") this.sortingList[row] = "ASC";

      this.getResourceList();
    },
    getQuery() {
      let search = this.search.toLowerCase();
      let query = {
        filter: {
          limit: this.perPage,
          skip: this.perPage * (this.currentPage - 1),
          order: Object.keys(this.sortingList).map(
            key => `${key} ${this.sortingList[key]}`
          ),
          where: {
            or: [
              { name: { like: `${search}`, options: "i" } },
              { tenant: { like: `${search}`, options: "i" } },
              { client: { like: `${search}`, options: "i" } }
            ]
          }
        }
      };
      return query;
    },
    async getWalletCredits(item) {
      let { data } = await ApiService.query(
        `${this.resource}/${item.id}/wallet`,
        {
          filter: { fields: { credits: true } }
        }
      );
      return data;
    },
    async getResourceCount() {
      let { data } = await ApiService.query(`${this.resource}/count`, {
        where: this.getQuery().filter.where
      });
      this.rows = data.count;
      return data.count;
    },
    async getResourceList() {
      this.showLoader = true;
      this.getResourceCount();

      let tenancies = await ApiService.query(this.resource, this.getQuery());
      console.log(tenancies);
      let data = tenancies.data;
      this.tenants = data;
      await Promise.all(
        this.tenants.map(async item => {
          item.wallet = { credits: 0 };
          item.wallet = await this.getWalletCredits(item);
        })
      );
      console.log(this.tenants);
      // this.tenants = data.map(tenancy => {
      //   tenancy.suscriptions = [];
      //   tenancy.roles?.forEach(async role => {
      //     let tenantRole = await this.getResourceRole(role)
      //     tenancy.suscriptions.push( tenantRole.name );
      //   })
      //   return tenancy;
      // });
      // this.tenants = data;
      this.showLoader = false;
      console.log(data);
    },
    async getResourceRole(id) {
      let { data } = await ApiService.get(`${this.resource}/roles`, id);
      //console.log(data);
      return data;
    },
    showDeleteModal(item) {
      this.deleteModal.clientId = new String(item.id);
      this.deleteModal.clientName = new String(item.name);
      this.$refs['deleteClientModal'].show();
    },
    async deleteResource(id) {
      this.deleteModal.loading = true;
      await ApiService.delete(`${this.resource}/${id}`)
        .then(() => {
          this.getResourceList();
          this.deleteModal.loading = false;
          this.$refs['deleteClientModal'].hide();
          this.deleteModal.confirmation = "";
          })
        .catch(() => {
          console.log("Not deleted");
          this.deleteModal.loading = false;
        });
    },
    async disableResource(id) {
      ApiService.patch(`${this.resource}/${id}`, { active: false })
        .then(() => this.getResourceList())
        .catch(() => {
          console.log("Not disabled");
        });
    },
    async enableResource(id) {
      ApiService.patch(`${this.resource}/${id}`, { active: true })
        .then(() => this.getResourceList())
        .catch(() => console.log("Not enabled"));
    },
    getResourceRoleName(id) {
      return new Promise(resolve => {
        this.getResourceRole(id).then(tenant => {
          resolve(tenant.name);
        });
      });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
